<template>
  <div>
    <div class="starWrap">
      <span class="title" :style="titleStyle">{{title}}</span>
      <div class="stars">
        <img
          v-for="(item,index) in list"
          :key="index"
          :src="require('../assets/image/stay/star.png')"
          alt
        />
        <!-- <img :src="require('../assets/image/stay/star.png')" alt />
                    <img :src="require('../assets/image/stay/star.png')" alt />
                    <img :src="require('../assets/image/stay/star.png')" alt />
        <img :src="require('../assets/image/stay/star.png')" alt />-->
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { checkIsNumber } from "@/utils/util";
export default {
  name: "m-star-bar",
  props: {
    title: {
      type: String,
      default: "星级评定："
    },
    titleColor: {
      type: String,
      default: "#333"
    },
    titleFontSize: {
      type: Number,
      default: 15
    },
    fontWeight: {
      type: String,
      default: "normal"
    },
    verticalAlign: {
      type: String,
      default: "text-bottom"
    },
    marginRight: {
      type: Number,
      default: 5
    },
    num: {
      type: String,
      default: "5"
    }
  },
  computed: {
    list() {
      let flag = checkIsNumber(this.num);
      if (flag) {
        return new Array(parseInt(this.num));
      } else {
        return new Array(5);
      }
    },
    titleStyle() {
      let obj = {
        fontSize: this.titleFontSize + "px",
        fontWeight: this.fontWeight,
        color: this.titleColor,
        verticalAlign: this.verticalAlign,
        marginRight: this.marginRight + "px"
      };
      return obj;
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.starWrap {
  > .title {
    // font-size: 15px;
    // color: $font-gray;
    vertical-align: text-bottom;
  }
  > .stars {
    // width: 115px;
    display: inline-block;
    > img {
      width: 18px;
      height: 19px;
      margin-right: 5px;
    }
  }
}
</style>
