<template>
  <div class="group">
    <div class="layoutLeft">
      <div class="picMask" v-show="showFlag"></div>
      <img :src="baseUrl + resData.main_image_id" alt />
      <div class="desc shadowBox_center">
        <span class="title">{{resData.title}}</span>
        <div class="starWrap">
          <m-star-bar class="starBar" title :num="resData.star"></m-star-bar>
        </div>
      </div>
    </div>
    <div class="layoutRight">
      <span>{{resData.restaurant_info}}</span>
      <!-- 轮播图 -->
      <div class="swiperWrap">
        <div class="swiper-container" id="cSwiper" @mouseenter="enter" @mouseleave="leave">
          <div class="swiper-wrapper">
            <div
              v-for="(item,index) in resData.list"
              :key="index"
              class="swiper-slide"
              :data-index="index"
              @mouseenter="enterPic(index)"
              @mouseleave="leavePic(index)"
            >
              <div class="layoutLeft">
                <img :src="baseUrl + item.main_image_id" alt />
              </div>
              <div class="layoutRight">
                <span class="title">{{item.title}}</span>
                <div class="contentBottom">
                  <span class="price">{{item.price}}</span>
                  <div class="icon" @click="orderclick">
                    <img v-if="cur==index" :src="require('../assets/image/food_en/icon1H.png')" alt />
                    <img v-else :src="require('../assets/image/food_en/icon1.png')" alt />
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="swiper-slide">Slide 2</div>
            <div class="swiper-slide">Slide 3</div>
            <div class="swiper-slide">Slide 4</div>
            <div class="swiper-slide">Slide 5</div>-->
            <!-- <div class="swiper-slide">Slide 6</div>
            <div class="swiper-slide">Slide 7</div>-->
          </div>
          <!-- Add Pagination -->
          <!-- <div class="swiper-pagination"></div> -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import StarBar from "@/components/starBar";
import Swiper from "swiper";
import { Pagination } from "swiper";

import config from "@/utils/config";
import { checkIsNumber } from "@/utils/util";

export default {
  name: "m-food-en-bar",
  components: {
    [StarBar.name]: StarBar
  },
  data() {
    return {
      showFlag: false,
      showIcon: false,
      cur: "-1"
    };
  },
  props: {
    resData: {
      type: Object,
      default: function() {
        return {
          main_image_id: "",
          title: "",
          restaurant_info: "",
          star: 4,
          rotation_chart: "",
          list: [
            {
              id: 0,
              title: "",
              price: "",
              main_image_id: ""
            }
          ]
        };
      }
    }
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  created() {
    this.$nextTick(function() {
      this.gallerySwiper();
    });
  },
  methods: {
    enterPic(index) {
      this.cur = index;
      // console.log("this.cur ", this.cur);
      // this.showIcon = true;
    },
    leavePic() {
      this.cur = "-1";
    },
    enter() {
      // console.log("in");
      this.showFlag = true;
    },
    leave() {
      // console.log("leave");
      this.showFlag = false;
    },
    orderclick() {
      this.$emit("orderclick");
    },
    gallerySwiper() {
      this.gallerySwiper = new Swiper(".swiper-container", {
        slidesPerView: 2.2,
        slidesPerColumn: 2,
        spaceBetween: 15,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
        // loop: "true"
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true
        // }
      });
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.group {
  padding-top: 37px;
  padding-bottom: 37px;
  border-bottom: 1px solid $font-gray;
  > .layoutLeft {
    position: relative;
    width: 426px;
    height: 526px;
    display: inline-block;
    vertical-align: top;
    margin-right: 32px;
    border-radius: 10px;
    > img {
      width: 424px;
      height: 449px;
    }
    .picMask {
      position: absolute;
      top: 0;
      left: 0;
      width: 426px;
      height: 526px;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.7);
      z-index: 10;
      display: block;
    }
    > .desc {
      position: absolute;
      width: 373px;
      height: 122px;
      left: 0;
      right: 0;
      bottom: 0px;
      margin: 0 auto;
      padding-top: 19px;
      border-radius: 4px;
      text-align: center;
      background: $main-white;
      z-index: 9;
      > span {
        font-size: 24px;
        font-weight: bold;
        color: $font-grayDark;
      }
      > .starWrap {
        margin-top: 10px;
      }
    }
  }
  > .layoutRight {
    width: 942px;
    height: 526px;
    display: inline-block;
    vertical-align: top;
    // background: red;
    > span {
      display: block;
      font-size: 18px;
      line-height: 1.5;
      height: 138px;
      // background: blue;
    }
  }
  .swiper-container-multirow-column > .swiper-wrapper {
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 56px;
  }
  .swiperWrap {
    // width: 931px;
    position: relative;
    width: 900px;
    height: 362px;
    // margin-left: 50px;

    .swiper-container {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-slide {
      // text-align: center;
      font-size: 18px;
      background: $bg-gray;
      height: calc((100% - 30px) / 2);
      border-radius: 10px;
      // border: 1px solid $font-grayDarkD;
      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      cursor: pointer;
      .layoutLeft {
        padding: 18px 0 18px 18px;
        width: 135px;
        height: 135px;
        margin-right: 18px;
        > img {
          width: 135px;
          height: 135px;
          border-radius: 50%;
        }
      }
      .layoutRight {
        position: relative;
        padding-top: 10px;
        width: 237px;
        height: 139px;
        box-sizing: border-box;
        .title {
          display: block;
          width: 217px;
          height: 95px;
          font-size: 20px;
          font-weight: bold;
          color: $font-grayDarkD;
          text-align: left;
        }
        .contentBottom {
          position: absolute;
          width: 217px;
          bottom: 0px;
          font-size: 20px;
          font-weight: bold;
          color: $main-red;
          .icon {
            position: absolute;
            right: 5px;
            bottom: -10px;
            display: inline-block;
            vertical-align: middle;
            transition: all 0.2s ease-out;
            //  &.active{
            //    display:block;
            //  }
          }
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      width: 32px;
      height: 362px;
      top: 0;
      margin-top: 0;
      // background:$main-blue;
      border-radius: 2px;
      // &:hover{
      //   background:$main-blueDark;
      // }
    }

    .swiper-button-prev {
      left: 0;
      background: #fff;
    }
    .swiper-button-next {
      right: 0;
      background: #fff;
    }
    .swiper-button-prev:after {
      color: $main-white;
      background-image: url(~@/assets/image/food/arrow_right.png) !important;
      background-size: 100% 100%;
      width: 32px;
      height: 65px;
      background-repeat: no-repeat;
      font-family: none;
      content: "";
    }

    .swiper-button-next:after {
      color: $main-white;
      background-image: url(~@/assets/image/food/arrow_left.png) !important;
      background-size: 100% 100%;
      width: 32px;
      height: 65px;
      background-repeat: no-repeat;
      font-family: none;
      content: "";
    }
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
      opacity: 1;
    }
  }
}
</style>
