// 判断是否为数字
function checkIsNumber(value) {
  // console.log('value,', value)
  if (parseInt(value).toString() === "NaN") {
    return false;
  } else {
    return true;
  }
};

export {
  checkIsNumber
}
